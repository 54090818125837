import { MouseEventHandler } from 'react'
import { NodeType } from '../datas/contracts/Data'
export interface NaveItemInterface {
  label: string
  slug: string
}
export type CompositeDataType = {
  main: NodeType | undefined
  items: Array<NodeType>
}
export interface MenuInterface {
  handleDrawerToggle: MouseEventHandler
  items: NaveItemInterface[]
}

const heightLogo = 30
export { heightLogo }
export default ''
