import React, { ReactNode, useContext, useReducer } from 'react'
import { FileType } from '../datas/contracts/Data'

type DataSiteType = {
  siteMetadata: Pick<
    Queries.SiteSiteMetadata,
    'aboutMe' | 'title' | 'mainTechs'
  >
}
type LocaleType = {
  edges: Array<{ node: Queries.Locale }>
}
export type SiteMedataDataContextType = {
  site: DataSiteType
  images: FileType
  techLogos: FileType
  me: Queries.File | undefined
  techsHelperContent:
    | Pick<Queries.MarkdownRemarkConnection, 'nodes'>
    | undefined
  locales: LocaleType | undefined
}

interface MetadataContextInterface extends SiteMedataDataContextType {
  dispatch: Function
}

const defaultValue: MetadataContextInterface = {
  site: {
    siteMetadata: {
      title: null,
      aboutMe: null,
      mainTechs: [],
    },
  },
  images: {
    edges: [],
  },
  techLogos: {
    edges: [],
  },
  me: undefined,
  techsHelperContent: undefined,
  locales: undefined,
  dispatch: (a: Partial<SiteMedataDataContextType>) => a,
}
const SiteMetaDataContextValue: React.Context<MetadataContextInterface> =
  React.createContext(defaultValue)

const SiteMetaDataContext: ({
  children,
}: {
  children: ReactNode
}) => JSX.Element = ({ children }) => {
  const [
    { site, images, me, techLogos, techsHelperContent, locales },
    dispatch,
  ] = useReducer(
    (state: SiteMedataDataContextType, newState: SiteMedataDataContextType) => {
      return { ...state, ...newState }
    },
    defaultValue
  )
  return (
    <SiteMetaDataContextValue.Provider
      value={{
        site,
        dispatch,
        images,
        me,
        techLogos,
        techsHelperContent,
        locales,
      }}
    >
      {children}
    </SiteMetaDataContextValue.Provider>
  )
}
export default SiteMetaDataContext
export const useSiteMetaDataContext = () =>
  useContext<MetadataContextInterface>(SiteMetaDataContextValue)
