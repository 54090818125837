import { MouseEvent } from 'react'

function listToMatrix<T>(list: T[], elementsPerSubArray: number): T[][] {
  let matrix: any[] = [],
    i,
    k

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++
      matrix[k] = []
    }

    matrix[k].push(list[i])
  }

  return matrix
}

const scrollTo = (
  event: MouseEvent<HTMLAnchorElement | HTMLSpanElement>,
  targetId: string
) => {
  const element = document.getElementById(targetId)
  if (element) {
    event.preventDefault()
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
export { listToMatrix, scrollTo }
