import { Box, Link, Tooltip } from '@mui/material'
import * as classes from '../../../styles/project.module.scss'
import * as React from 'react'
import { useSiteMetaDataContext } from '../../../context/siteMetaDataContext'
import { useMemo } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

const Profiles: React.FC<{ iconWidth?: number }> = ({ iconWidth = 40 }) => {
  const { site, techLogos } = useSiteMetaDataContext()
  const { t } = useI18next()
  const logos = useMemo(() => {
    return techLogos.edges
      .filter((logo) =>
        ['gmail', 'github', 'cloud', 'linkedIn'].includes(logo.node.name)
      )
      .map((file) => file.node)
  }, [techLogos])
  return (
    <>
      {logos.map((logo) => {
        const title = t(logo.name !== 'cloud' ? 'tooltipLabelIcon' : 'viewCV', {
          plateform: logo.name,
        })
        return (
          logo.svg && (
            <Tooltip title={title} key={logo.name}>
              <Link
                target={'_blank'}
                key={logo.name}
                href={`${
                  logo.name === 'github'
                    ? process.env.GITHUB_PROFIL
                    : logo.name === 'gmail'
                    ? 'mailto:' + site.siteMetadata.aboutMe?.email
                    : logo.name === 'linkedIn'
                    ? process.env.LINKEDIN_PROFIL
                    : process.env.CVLINK
                }`}
              >
                <img
                  style={{ width: `${iconWidth}px` }}
                  alt={logo.name}
                  src={logo.svg.dataURI as string}
                  className={classes.static_image}
                />
              </Link>
            </Tooltip>
          )
        )
      })}
    </>
  )
}
export default Profiles
