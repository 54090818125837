import React, { useEffect, MouseEvent, useMemo } from 'react'
import {
  sidebar,
  sidebarNav,
  sidebarNavItems,
  links,
  mePic,
} from './Sidebar.module.scss'

import { Box, Grid, Link, Typography } from '@mui/material'

import { useSiteMetaDataContext } from '../../context/siteMetaDataContext'
import SwitchLang from '../../pages/components/switchLang'
import { useI18next } from 'gatsby-plugin-react-i18next'
import EmailIcon from '@mui/icons-material/Email'
import Img, { FixedObject } from 'gatsby-image'
import LinearWithValueLabel from '../progressive/bar'
import useStyles from '../customScrollbar/customerScrollbar'
import { heightLogo } from '../../utils/constant'
import { useTheme } from '@mui/material'
import { scrollTo } from '../../utils/functions'
import Profiles from '../../pages/components/portfolio/profiles'

const Sidebar = () => {
  const { site, me, techLogos, techsHelperContent } = useSiteMetaDataContext()
  const classes = useStyles()
  const { t } = useI18next()
  const theme = useTheme()

  const getHelperDescription = (name: string): string => {
    if (techsHelperContent) {
      const description = techsHelperContent.nodes.find(
        ({ frontmatter }) => frontmatter && frontmatter.name === name
      )

      if (description) {
        return description.html as string
      }
    }
    return ''
  }
  const resolveUnknownLogoCandidates = (
    logoName: string
  ): React.ReactElement => {
    const logoCandidates = techLogos.edges.filter((e) =>
      logoName.match(/script/)
        ? e.node.name.match(/script/)
        : ['CSS3', 'HTML5'].includes(e.node.name)
    )

    return (
      <>
        {logoCandidates.map(
          (logo, key) =>
            logo.node.svg?.dataURI && (
              <img
                key={key}
                src={logo.node.svg?.dataURI}
                height={heightLogo}
                style={{ margin: 3 }}
              />
            )
        )}
      </>
    )
  }

  return (
    <Grid
      className={sidebar + ' ' + classes.customScrollbar}
      sx={{ backgroundColor: theme.palette.primary.light }}
    >
      <SwitchLang />

      <Typography component={'h3'} fontWeight={'bold'}>
        # {t('title')}
      </Typography>
      {site.siteMetadata.mainTechs?.map((tech, index) => (
        <Typography component={'h3'} fontWeight={'bold'} key={index}>
          # {tech}
        </Typography>
      ))}
      <Typography component={'h1'} mt={5}>
        {site.siteMetadata.aboutMe?.firstname}
      </Typography>
      {me && me.childImageSharp?.fixed && (
        <Img
          className={mePic}
          fixed={me.childImageSharp.fixed as FixedObject}
          alt={'me'}
        />
      )}
      <Box component={'div'} mt={3}>
        <Profiles iconWidth={25} />
      </Box>
      <ul className={sidebarNav}>
        {['about', 'portfolio', 'coords'].map((el) => (
          <li className={sidebarNavItems} key={el}>
            <Link
              href={`#${el}`}
              onClick={(e) => scrollTo(e, el)}
              className={links}
            >
              {t(el)}
            </Link>
          </li>
        ))}
      </ul>

      {site.siteMetadata.aboutMe?.techs?.map((tech) => {
        const logoSVG = techLogos.edges.find(
          (e) => tech && e.node.name === tech.name
        )

        return (
          tech?.name && (
            <LinearWithValueLabel
              label={tech.name as string}
              value={100 * ((tech.level as number) / 5)}
              key={tech.name}
              hasHelperToolTip={true}
              helperContent={getHelperDescription(tech.name)}
              icon={
                logoSVG && logoSVG.node.svg?.dataURI ? (
                  <img src={logoSVG.node.svg.dataURI} height={heightLogo} />
                ) : (
                  resolveUnknownLogoCandidates(tech.name)
                )
              }
            />
          )
        )
      })}
    </Grid>
  )
}
export default Sidebar
