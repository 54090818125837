import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'white',
    },
  },
})

export default useStyles
