import { Box, Typography } from '@mui/material'
import React from 'react'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { useSiteMetaDataContext } from '../../context/siteMetaDataContext'

const Footer = () => {
  const { t } = useI18next()
  const {
    site: {
      siteMetadata: { aboutMe },
    },
  } = useSiteMetaDataContext()
  return (
    <Box component={'div'} mt={'30px'} textAlign={'center'}>
      <Typography component={'div'} color={'primary'}>
        <Trans
          i18nKey={'textFooter'}
          values={{
            contact: aboutMe?.contact,
            me: 'Tiana RANDRIANARIVONY',
          }}
          components={{
            a: <a href={'tel:' + aboutMe?.contact} />,
            b: <b />,
            h3: <h3 />,
          }}
        ></Trans>
      </Typography>
    </Box>
  )
}
export default Footer
