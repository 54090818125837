import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { useState } from 'react'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => {
  return (
    <Tooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={15000}
      {...props}
      sx={{ color: '#1c5a4b40' }}
      classes={{ popper: className }}
    />
  )
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))
export default HtmlTooltip
