exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-footer-tsx": () => import("./../../../src/pages/components/footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-tsx" */),
  "component---src-pages-components-layout-tsx": () => import("./../../../src/pages/components/layout.tsx" /* webpackChunkName: "component---src-pages-components-layout-tsx" */),
  "component---src-pages-components-portfolio-about-tsx": () => import("./../../../src/pages/components/portfolio/about.tsx" /* webpackChunkName: "component---src-pages-components-portfolio-about-tsx" */),
  "component---src-pages-components-portfolio-contact-tsx": () => import("./../../../src/pages/components/portfolio/contact.tsx" /* webpackChunkName: "component---src-pages-components-portfolio-contact-tsx" */),
  "component---src-pages-components-portfolio-profiles-tsx": () => import("./../../../src/pages/components/portfolio/profiles.tsx" /* webpackChunkName: "component---src-pages-components-portfolio-profiles-tsx" */),
  "component---src-pages-components-portfolio-thankyou-tsx": () => import("./../../../src/pages/components/portfolio/thankyou.tsx" /* webpackChunkName: "component---src-pages-components-portfolio-thankyou-tsx" */),
  "component---src-pages-components-portfolio-works-tsx": () => import("./../../../src/pages/components/portfolio/works.tsx" /* webpackChunkName: "component---src-pages-components-portfolio-works-tsx" */),
  "component---src-pages-components-switch-lang-tsx": () => import("./../../../src/pages/components/switchLang.tsx" /* webpackChunkName: "component---src-pages-components-switch-lang-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

