import * as React from 'react'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import HtmlTooltip from '../tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Grid } from '@mui/material'

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

const LinearWithValueLabel: React.FC<{
  label: string
  value: number
  hasHelperToolTip?: boolean
  helperContent?: string
  icon?: React.ReactElement
}> = ({ label, value, hasHelperToolTip, icon, helperContent }) => {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < value) {
          return prevProgress + 1
        } else {
          clearInterval(timer)
          return prevProgress
        }
      })
    }, value)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <Box width={'100%'} textAlign={'left'}>
      <Grid display={'flex'} justifyContent={'space-between'}>
        <Typography
          component={'span'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            {label}
            {icon}
          </>
        </Typography>
        {hasHelperToolTip && helperContent && (
          <HtmlTooltip title={TitleContent({ content: helperContent })}>
            <HelpOutlineIcon color={'primary'} />
          </HtmlTooltip>
        )}
      </Grid>
      <LinearProgressWithLabel value={progress} />
    </Box>
  )
}
export default LinearWithValueLabel

const TitleContent: React.FC<{ content: string }> = ({ content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }}></div>
)
