// extracted by mini-css-extract-plugin
export var card = "project-module--card--e528b";
export var card__description = "project-module--card__description--73414";
export var card__header = "project-module--card__header--611f9";
export var card__image = "project-module--card__image--c91e2";
export var card__overlay = "project-module--card__overlay--fb23e";
export var card__status = "project-module--card__status--eb361";
export var card__title = "project-module--card__title--4c945";
export var cards = "project-module--cards--c4c4a";
export var head = "project-module--head--700af";
export var heading = "project-module--heading--416ba";
export var static_image = "project-module--static_image--eb9ff";