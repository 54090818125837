import { GatsbyBrowser } from 'gatsby'
import * as React from 'react'
import Layout from './src/pages/components/layout'
declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary']
  }
  interface PaletteOptions {
    primaryLight: PaletteOptions['primary']
  }
}
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  props,
  element,
}) => {
  return React.cloneElement(
    element, // I18nextProvider
    props,
    element.props.children &&
      React.cloneElement(
        element.props.children, // I18nextContext.Provider
        element.props.children?.props,
        React.createElement(
          Layout,
          props,
          element.props.children?.props.children
        )
      )
  )
}
