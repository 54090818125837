import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { languages as style } from './menu/styles/appBar.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
const SwitchLang = () => {
  const { originalPath } = useI18next()
  const flags: Pick<Queries.Query, 'allFile'> = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "flags" }, ext: { eq: ".svg" } }
      ) {
        edges {
          node {
            svg {
              dataURI
            }
            name
          }
        }
      }
    }
  `)
  return (
    <ul className={style}>
      {flags.allFile.edges.map(({ node }, key) => (
        <li key={key}>
          <Link to={originalPath} language={node.name}>
            {node.svg?.dataURI ? (
              <img src={node.svg?.dataURI} width={'20px'} height={'20px'} />
            ) : (
              node.name
            )}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default SwitchLang
