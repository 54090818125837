import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#22223b82',
      light: '#1c5a4b40',
    },
    secondary: {
      main: '#616161',
      contrastText: '#616161',
    },
    primaryLight: {
      main: 'rgba(97,97,97,0.57)',
      contrastText: '#616161',
    },
  },
  typography: {
    fontFamily: 'Quicksand!important',
  },
})
export default theme
