import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import SiteMetaDataContext from '../../context/siteMetaDataContext'
import '../../styles/portfolio.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import {
  Box,
  Divider,
  Drawer,
  Grid,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  Zoom,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Footer from './footer'
import { KeyboardArrowUp } from '@mui/icons-material'
import { Fab } from 'gatsby-theme-material-ui'
import { scrollTo } from '../../utils/functions'
const Layout: React.FC<PageProps> = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:899px')
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const theme = useTheme()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })
  return (
    <SiteMetaDataContext>
      {isMobile && (
        <IconButton
          onClick={handleOpen}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            m: 1,
            display: 'inline',
            position: 'absolute',
            padding: '5px 15px 0px 15px',
            backgroundColor: '#ffffff96',
            borderRadius: '4px',
            zIndex: 1,
          }}
        >
          <MenuIcon style={{ color: '#82ab23' }} />
        </IconButton>
      )}
      <Box component={'div'} display={'flex'}>
        <Grid
          xs={3}
          item
          className="side"
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Drawer anchor="left" open={open} onClose={handleClose}>
            <Sidebar />
          </Drawer>
          <Sidebar />
        </Grid>
        <Grid xs={9} className="main" item width={'100%'}>
          {children}
          <Divider variant={'middle'} />
          <Zoom in={trigger}>
            <Box
              onClick={(e) => scrollTo(e, 'top')}
              component={'div'}
              sx={{
                position: 'fixed',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
              }}
            >
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUp />
              </Fab>
            </Box>
          </Zoom>
          <Footer />
        </Grid>
      </Box>
    </SiteMetaDataContext>
  )
}
export default Layout
